import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    columnlist: JSON.parse(sessionStorage.getItem('POWERLIST')),
    powerList: JSON.parse(sessionStorage.getItem('POWERLISTS')),
    cateId: null,
    linecateValue: null,
    linecateValue1: null, //线路列表页分类
    hotelcateValue: null,
    hotelcateId: null, //酒店列表分类
    roomcateId: null, //客房分类
    sceneryValue: null,
    sceneryCateId: null, //景区分类\
    ticketCateId: null, // 门票分类
    mallShopData: {}, // 精选商城店铺数据
  },
  mutations: {
    setColumnList(state, data) {
      state.columnlist = data
      sessionStorage.setItem('POWERLIST', JSON.stringify(data))
      var arr = []
      data.forEach(item => {
        item.manageColumnList.forEach(item1 => {
          item1.manageColumnList.forEach(item2 => {
            item2.managePowerList.map((item3) => {
              arr.push(item3.powerValue)
            })
          })
        })
      });
      state.powerList = arr
      sessionStorage.setItem('POWERLISTS', JSON.stringify(arr))
    },
    setMallShopData(state, payload) {
      state.mallShopData = payload
      console.log('vuex保存值', state.mallShopData)
    },

    //酒店分类
    setHotelcateValue(state, data) {
      state.hotelcateValue = data || null
    },
    setHotelcateId(state, data) {
      state.hotelcateId = data || null
    },

    //客房分类
    setRoomcateId(state, data) {
      state.roomcateId = data || null
    },
    //景区分类
    setsceneryValue(state, data) {
      state.sceneryValue = data || null
    },
    setsceneryCateId(state, data) {
      state.sceneryCateId = data || null
    },
    //门票分类
    setticketCateId(state, data) {
      state.ticketCateId = data || null
    },
    //线路分类
    setLinecateId(state, data) {
      state.cateId = data || null
    },
    setLinecateValue(state, data) {
      state.linecateValue = data || null
    },
    setLinecateValue1(state, data) {
      state.linecateValue1 = data || null
    },
  },
  actions: {
  },
  modules: {
  }
})
