import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'login1',
		redirect: '/index'
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index')
	},
	{
		path: '/pagePrint/:coding',
		name: 'pagePrint',
		component: () => import('../views/order/printPage.vue'),
		props: true
	},

	{
		path: '/index',
		name: 'index',
		redirect: '/home',
		component: () => import('../views/index/index'),
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('../views/index/home')
			},
			{
				path: '/powerlist',
				name: 'powerList',
				component: () => import('../views/power/powerList')
			},
			{
				path: '/updateuseruassword',
				name: 'updateuseruassword',
				component: () => import('../views/login/UpdateUserPassword')
			},
			{
				path: '/aboutus',
				name: 'aboutus',
				component: () => import('../views/systemManagement/aboutUs')
			},
			{
				path: '/agreement',
				name: 'agreement',
				component: () => import('../views/systemManagement/agreement')
			},
			{
				path: '/editAgree/:id',
				name: 'editAgree',
				component: () =>
					import('../views/systemManagement/editAgree'),
				props: true
			},
			{
				path: '/administratorList',
				name: 'administrator',
				component: () =>
					import('../views/administrator/administratorsList'),
				props: true
			},
			{
				path: '/settingPay',
				name: 'settingPay',
				component: () =>
					import('../views/administrator/settingPay'),
				props: true
			},
			{
				path: '/settingTax',
				name: 'settingTax',
				component: () =>
					import('../views/administrator/settingTax'),
				props: true
			},
			{
				path: '/setting',
				name: 'setting',
				component: () =>
					import('../views/administrator/setting'),
				props: true
			},
			// {
			// 	path: '/setting',
			// 	name: 'setting',
			// 	component: () =>
			// 		import('../views/administrator/setting'),
			// 	props: true
			// },
			{
				path: '/settingTime',
				name: 'settingTime',
				component: () =>
					import('../views/administrator/settingTime'),
				props: true
			},
			{
				path: '/writeoff',
				name: 'writeoff',
				component: () => import('../views/administrator/writeoff/writeoff')
			},
			{
				path: '/writeoffPeople',
				name: 'writeoffPeople',
				
				component: () => import('../views/administrator/writeoff/writeoffPeople')
			},
			{
				path: '/writeoffForUserAdd',
				name: 'writeoffForUserAdd',
				component: () => import('../views/administrator/writeoff/writeoffForUserAdd')
			},
			{
				path: '/writeoffForUser',
				name: 'writeoffForUser',
				component: () => import('../views/administrator/writeoff/writeoffForUser')
			},
			{
				path: '/allRegList',
				name: 'allRegList',
				component: () =>
					import('../views/administrator/allRegList'),
				props: true
			},
			{
				path: '/regList',
				name: 'regList',
				component: () =>
					import('../views/administrator/regList'),
				props: true
			},
			{
				path: '/addAdministrator',
				name: 'addAdministrator',
				component: () =>
					import('../views/administrator/addAdministrator')
			},
			{
				path: '/editAdmin/:id',
				name: 'editAdmin',
				component: () => import('../views/administrator/editAdmin'),
				props: true
			},
			{
				path: '/UpdateAdminPassword/:id',
				name: 'UpdateAdminPassword',
				component: () =>
					import('../views/administrator/UpdateAdminPassword'),
				props: true
			},
			{
				path: '/rolelist',
				name: 'rolelist',
				component: () => import('../views/roleManagement/roleList')
			},
			{
				path: '/recruit',
				name: 'recruit',
				component: () => import('../views/recruit/recruit')
			},
			{
				path: '/recruitAdd',
				name: 'recruitAdd',
				component: () => import('../views/recruit/recruitAdd')
			},
			{
				path: '/news',
				name: 'news',
				component: () => import('../views/news/news')
			},
			{
				path: '/newsAdd',
				name: 'newsAdd',
				component: () => import('../views/news/newsAdd')
			},
			{
				path: '/newsCate',
				name: 'newsCate',
				component: () => import('../views/news/newsCate')
			},
			{
				path: '/newsCateAdd',
				name: 'newsCateAdd',
				component: () => import('../views/news/newsCateAdd')
			},
			{
				path: '/roleEmpowerment/:id',
				name: 'roleEmpowerment',
				component: () =>
					import('../views/roleManagement/roleEmpowerment'),
				props: true
			},
			{
				path: '/columnlist',
				name: 'columnlist',
				component: () => import('../views/column/columnList')
			},
			{
				path: '/secondcolumnlist/:id',
				name: 'secondcolumnlist',
				component: () => import('../views/column/secondColumnList'),
				props: true
			},
			{
				path: '/thirdcolumnList/:id',
				name: 'thirdColumnList',
				component: () => import('../views/column/thirdColumnList'),
				props: true
			},
			{
				path: '/hotelCatelList',
				name: 'hotelCatelList',
				
				component: () => import('../views/hotel/hotelCateList')
			},
			{
				path: '/hotelDetails/:id',
				name: 'hotelDetails',
				component: () => import('../views/hotel/hotelDetails'),
				props: true
			},
			{
				path: '/secondLevel/:id',
				name: 'secondLevel',
				component: () => import('../views/hotel/secondLevel'),
				props: true
			},
			{
				path: '/hotelList',
				name: 'hotelList',
				component: () => import('../views/hotel/hotelList')
			},
			{
				path: '/addhotel',
				name: 'addhotel',
				component: () => import('../views/hotel/addHotel')
			},
			{
				path: '/edithotel/:id',
				name: 'edithotel',
				component: () => import('../views/hotel/editHotel'),
				props: true
			},
			{
				path: '/hotelJournal/:id',
				name: 'hotelJournal',
				component: () => import('../views/hotel/hotelJournal'),
				props: true
			},
			{
				path: '/hotelJournal/:id',
				name: 'hotelJournal',
				component: () => import('../views/hotel/hotelJournal'),
				props: true
			},
			{
				path: '/hotelLogDetails/:id',
				name: 'hotelLogDetails',
				component: () => import('../views/hotel/hotelLogDetails'),
				props: true
			},
			{
				path: '/roomList',
				name: 'roomList',
				component: () => import('../views/room/roomList')
			},
			{
				path: '/addRoom',
				name: 'addRoom',
				component: () => import('../views/room/addRoom')
			},
			{
				path: '/editRoom/:id',
				name: 'editRoom',
				component: () => import('../views/room/editRoom'),
				props: true
			},
			{
				path: '/roomLog/:id',
				name: 'roomLog',
				component: () => import('../views/room/roomLog'),
				props: true
			},
			{
				path: '/roomLogDetails/:id',
				name: 'roomLogDetails',
				component: () => import('../views/room/roomLogDetails'),
				props: true
			},
			{
				path: '/roomPrice/:id',
				name: 'roomPrice',
				component: () => import('../views/room/roomPrice'),
				props: true
			},
			{
				path: '/linkingActivity',
				name: 'linkingActivity',
				component: () => import('../views/room/linkingActivity'),
				props: true
			},
			{
				path: '/addActivityLinking',
				name: 'addActivityLinking',
				component: () => import('../views/room/addActivityLinking'),
				props: true
			},
			{
				path: '/roomListLinking',
				name: 'roomListLinking',
				component: () => import('../views/room/roomListLinking'),
				props: true
			},
			{
				path: '/roomPriceLinking/:id',
				name: 'roomPriceLinking',
				component: () => import('../views/room/roomPriceLinking'),
				props: true
			},
			{
				path: '/commodityManagementPrice/:id',
				name: 'commodityManagementPrice',
				component: () => import('../views/room/commodityManagementPrice'),
				props: true
			},
			{
				path: '/commodityManagement',
				name: 'commodityManagement',
				component: () => import('../views/room/commodityManagement'),
				props: true
			},
			{
				path: '/sceneryCateList',
				name: 'sceneryCateList',
				component: () => import('../views/scenery/sceneryCateList')
			},
			{
				path: '/sceneryList',
				name: 'sceneryList',
				component: () => import('../views/scenery/sceneryList'),
				props: true
			},
			{
				path: '/secondSceneryCateList/:id',
				name: 'secondSceneryCateList',
				component: () =>
					import('../views/scenery/secondSceneryCateList'),
				props: true
			},
			{
				path: '/addScenery',
				name: 'addScenery',
				component: () => import('../views/scenery/addScenery'),
				props: true
			},
			{
				path: '/editScenery/:id',
				name: 'editScenery',
				component: () => import('../views/scenery/editScenery'),
				props: true
			},
			{
				path: '/allSceneries',
				name: 'allSceneries',
				component: () => import('../views/scenery/allSceneries')
			},
			{
				path: '/sceneryLog/:id',
				name: 'sceneryLog',
				component: () => import('../views/scenery/sceneryLog'),
				props: true
			},
			{
				path: '/sceneryLogDetails/:id',
				name: 'sceneryLogDetails',
				component: () => import('../views/scenery/sceneryLogDetails'),
				props: true
			},
			{
				path: '/ticketList',
				name: 'ticketList',
				component: () => import('../views/ticket/ticketList')
			},
			{
				path: '/ticketCate',
				name: 'ticketCate',
				component: () => import('../views/ticket/ticketCate')
			},
			{
				path: '/addTicket/:id',
				name: 'addTicket',
				component: () => import('../views/ticket/addTicket'),
				props: true
			},
			{
				path: '/addTicketCate/:id/:cateName',
				name: 'addTicketCate',
				component: () => import('../views/ticket/addTicketCate'),
				props: true
			},
			{
				path: '/ticketPrice/:id',
				name: 'ticketPrice',
				component: () => import('../views/ticket/ticketPrice'),
				props: true
			},
			{
				path: '/ticketLog/:id',
				name: 'ticketLog',
				component: () => import('../views/ticket/ticketLog'),
				props: true
			},
			{
				path: '/ticketLogDetails/:id',
				name: 'ticketLogDetails',
				component: () => import('../views/ticket/ticketLogDetails'),
				props: true
			},
			{
				path: '/travelCateList',
				name: 'travelCateList',
				component: () => import('../views/travel/travelCateList')
			},
			{
				path: '/customInfo',
				name: 'customInfo',
				component: () => import('../views/travel/customInfo')
			},
			{
				path: '/customInfoDetails',
				name: 'customInfoDetails',
				component: () => import('../views/travel/customInfoDetails')
			},
			{
				path: '/trip',
				name: 'trip',
				component: () => import('../views/travel/trip')
			},
			{
				path: '/tripAdd',
				name: 'tripAdd',
				component: () => import('../views/travel/tripAdd')
			},
			{
				path: '/scenic',
				name: 'scenic',
				component: () => import('../views/travel/scenic')
			},
			{
				path: '/scenicAdd',
				name: 'scenicAdd',
				component: () => import('../views/travel/scenicAdd')
			},
			{
				path: '/travelCateAd', //线路广告管理
				name: 'travelCateAd',
				component: () => import('../views/travel/travelCateAd')
			},
			{
				path: '/addTravelCateAd', //添加线路广告
				name: 'addTravelCateAd',
				component: () => import('../views/travel/addTravelCateAd')
			},
			{
				path: '/secondTravelCateList/:id',
				name: 'secondTravelCateList',
				component: () =>
					import('../views/travel/secondTravelCateList'),
				props: true
			},
			{
				path: '/thirdTravelCateList/:id',
				name: 'thirdTravelCateList',
				component: () =>
					import('../views/travel/thirdTravelCateList'),
				props: true
			},
			{
				path: '/lineList',
				name: 'lineList',
				component: () => import('../views/travel/lineList')
			},
			{
				path: '/addLine/:id',
				name: 'addLine',
				component: () => import('../views/travel/addLine'),
				props: true
			},
			{
				path: '/lineLog/:id',
				name: 'lineLog',
				component: () => import('../views/travel/lineLog'),
				props: true
			},
			{
				path: '/lineLogDetails/:id',
				name: 'lineLogDetails',
				component: () => import('../views/travel/lineLogDetails'),
				props: true
			},
			{
				path: '/travelSpecsList/:id',
				name: 'travelSpecsList',
				component: () => import('../views/travel/travelSpecsList'),
				props: true
			},
			{
				path: '/addTravelSpecs/:id/:addOrEdit/:sellType',
				name: 'addTravelSpecs',
				component: () => import('../views/travel/addTravelSpecs'),
				props: true
			},
			{
				path: '/travelSpecsPrice/:id/',
				name: 'travelSpecsPrice',
				component: () => import('../views/travel/travelSpecsPrice'),
				props: true
			},
			{
				path: '/travelSpecsLog/:id/',
				name: 'travelSpecsLog',
				component: () => import('../views/travel/travelSpecsLog'),
				props: true
			},
			{
				path: '/travelSpecsLogDetails/:id/',
				name: 'travelSpecsLogDetails',
				component: () =>
					import('../views/travel/travelSpecsLogDetails'),
				props: true
			},
			{
				path: '/integralSetting',
				name: 'integralSetting',
				component: () => import('../views/marketing/integralSetting')
			},
			{
				path: '/petTime',
				name: 'petTime',
				component: () => import('../views/marketing/petPowder/petTime')
			},
			{
				path: '/petList',
				name: 'petList',
				component: () => import('../views/marketing/petPowder/petList')
			},
			{
				path: '/lockSetting',
				name: 'lockSetting',
				component: () => import('../views/marketing/lockSetting')
			},
			{
				path: '/activityNotice',
				name: 'activityNotice',
				component: () => import('../views/marketing/activityNotice')
			},
			{
				path: '/prodNotice',
				name: 'prodNotice',
				component: () => import('../views/marketing/prodNotice')
			},
			{
				path: '/noticeSetting',
				name: 'noticeSetting',
				component: () => import('../views/marketing/noticeSetting')
			},
			{
				path: '/sendUserList',
				name: 'sendUserList',
				component: () => import('../views/marketing/sendUserList')
			},
			{
				path: '/userLevelList',
				name: 'userLevelList',
				component: () =>
					import('../views/marketing/Level/userLevelList')
			},
			{
				path: '/couponList',
				name: 'couponList',
				component: () =>
					import('../views/marketing/coupon/couponList')
			},
			{
				path: '/couponCate',
				name: 'couponCate',
				component: () =>
					import('../views/marketing/coupon/couponCate')
			},
			{
				path: '/addCouponCate',
				name: 'addCouponCate',
				component: () =>
					import('../views/marketing/coupon/addCouponCate')
			},
			{
				path: '/couponedList',
				name: 'couponedList',
				component: () =>
					import('../views/marketing/coupon/couponedList')
			},
			{
				path: '/addCoupon/:id',
				name: 'addCoupon',
				component: () =>
					import('../views/marketing/coupon/addCoupon'),
				props: true
			},
			{
				path: '/posterPlace',
				name: 'posterPlace',
				component: () =>
					import('../views/marketing/poster/posterPlace')
			},
			{
				path: '/secondPoster/:id',
				name: 'secondPoster',
				component: () =>
					import('../views/marketing/poster/secondPoster'),
				props: true
			},
			{
				path: '/posterList',
				name: 'posterList',
				component: () =>
					import('../views/marketing/poster/posterList')
			},
			{
				path: '/addPoster/:id',
				name: 'addPoster',
				component: () =>
					import('../views/marketing/poster/addPoster'),
				props: true
			},
			{
				path: '/groupBuyList',
				name: 'groupBuyList',
				component: () =>
					import('../views/marketing/groupBuy/groupBuyList')
			},
			{
				path: '/groupLog',
				name: 'groupLog',
				component: () =>
					import('../views/marketing/groupBuy/groupLog')
			},
			{
				path: '/groupLogDetails',
				name: 'groupLogDetails',
				component: () =>
					import('../views/marketing/groupBuy/groupLogDetails')
			},
			{
				path: '/addGroupBuy/:id/:addid/:type',
				name: 'addGroupBuy',
				component: () =>
					import('../views/marketing/groupBuy/addGroupBuy'),
				props: true
			},
			{
				path: '/selectProducts',
				name: 'selectProducts',
				component: () =>
					import('../views/marketing/groupBuy/selectProducts')
			},
			{
				path: '/upgradeList/:id',
				name: 'upgradeList',
				component: () =>
					import('../views/marketing/groupBuy/upgradeList'),
				props: true
			},
			{
				path: '/upgradePrice/:id',
				name: 'upgradePrice',
				component: () =>
					import('../views/marketing/groupBuy/upgradePrice'),
				props: true
			},
			{
				path: '/seckillInfoList',
				name: 'seckillInfoList',
				component: () =>
					import('../views/marketing/seckillInfo/seckillInfoList')
			},
			{
				path: '/seckillLog',
				name: 'seckillLog',
				component: () =>
					import('../views/marketing/seckillInfo/seckillLog')
			},
			{
				path: '/seckillLogDetails',
				name: 'seckillLogDetails',
				component: () =>
					import('../views/marketing/seckillInfo/seckillLogDetails')
			},
			{
				path: '/presaleList',
				name: 'presaleList',
				component: () =>
					import('../views/marketing/seckillInfo/presaleList')
			},
			{
				path: '/seckillPrice/:id',
				name: 'seckillPrice',
				component: () => import('../views/marketing/seckillInfo/seckillPrice'),
				props: true
			},
			{
				path: '/presalePrice/:id',
				name: 'presalePrice',
				component: () => import('../views/marketing/seckillInfo/presalePrice'),
				props: true
			},
			{
				path: '/addSeckill/:id/:addid/:type',
				name: 'addSeckill',
				component: () =>
					import('../views/marketing/seckillInfo/addSeckill'),
				props: true
			},
			{
				path: '/addPresale/:id/:addid/:type',
				name: 'addPresale',
				component: () =>
					import('../views/marketing/seckillInfo/addPresale'),
				props: true
			},
			{
				path: '/secKillUpgradeList/:id',
				name: 'secKillUpgradeList',
				component: () =>
					import('../views/marketing/seckillInfo/secKillUpgradeList'),
				props: true
			},
			{
				path: '/presaleUpgradeList/:id',
				name: 'presaleUpgradeList',
				component: () =>
					import('../views/marketing/seckillInfo/presaleUpgradeList'),
				props: true
			},
			{
				path: '/seckillUpgradePrice/:id',
				name: 'seckillUpgradePrice',
				component: () =>
					import(
						'../views/marketing/seckillInfo/seckillUpgradePrice'
					),
				props: true
			},
			{
				path: '/signIntegralSetting',
				name: 'signIntegralSetting',
				component: () =>
					import(
						'../views/marketing/signIn/integralSetting'
					),
				props: true
			},
			{
				path: '/visaBonus',
				name: 'visaBonus',
				component: () =>
					import(
						'../views/marketing/signIn/visaBonus'
					),
				props: true
			},
			{
				path: '/presaleUpgradePrice/:id',
				name: 'presaleUpgradePrice',
				component: () =>
					import(
						'../views/marketing/seckillInfo/presaleUpgradePrice'
					),
				props: true
			},
			{
				path: '/giftBagList',
				name: 'giftBagList',
				component: () =>
					import(
						'../views/marketing/seckillInfo/giftBagList'
					),
				props: true
			},
			{
				path: '/giftClassifyList',
				name: 'giftClassifyList',
				component: () =>
					import(
						'../views/marketing/seckillInfo/giftClassifyList'
					),
				props: true
			},
			{
				path: '/addgiftBag',
				name: 'addgiftBag',
				component: () =>
					import(
						'../views/marketing/seckillInfo/addgiftBag'
					),
				props: true
			},
			{
				path: '/giftPackageList',
				name: 'giftPackageList',
				component: () =>
					import(
						'../views/marketing/seckillInfo/giftPackageList'
					),
				props: true
			},
			{
				path: '/addGiftPackage',
				name: 'addGiftPackage',
				component: () =>
					import(
						'../views/marketing/seckillInfo/addGiftPackage'
					),
				props: true
			},
			{
				path: '/giftPackagePrice/:id/:packId',
				name: 'giftPackagePrice',
				component: () =>
					import(
						'../views/marketing/seckillInfo/giftPackagePrice'
					),
				props: true
			},
			{
				path: '/giftPackageUpgradeList/:id',
				name: 'giftPackageUpgradeList',
				component: () =>
					import(
						'../views/marketing/seckillInfo/giftPackageUpgradeList'
					),
				props: true
			},
		
		{
			path: '/giftPackageUpgradPrice/:id',
			name: 'giftPackageUpgradPrice',
			component: () =>
				import(
					'../views/marketing/seckillInfo/giftPackageUpgradPrice'
				),
			props: true
		},
		{
			path: '/giftviewLog',
			name: 'giftviewLog',
			component: () =>
				import(
					'../views/marketing/seckillInfo/giftviewLog'
				),
			props: true
		},
		{
			path: '/giftPackageViewLog',
			name: 'giftPackageViewLog',
			component: () =>
				import(
					'../views/marketing/seckillInfo/giftPackageViewLog'
				),
			props: true
		},
			
			{
				path: '/vipList',
				name: 'vipList',
				component: () => import('../views/vip/vipList')
			},
			{
				path: '/distributionUser',
				name: 'distributionUser',
				component: () => import('../views/vip/distributionUser')
			},
			{
				path: '/distributionPeople',
				name: 'distributionPeople',
				component: () => import('../views/vip/distributionPeople')
			},
			{
				path: '/distributionPeopleUser',
				name: 'distributionPeopleUser',
				component: () => import('../views/vip/distributionPeopleUser')
			},

			
			// 精选商城
			{
				path: '/selectMall/typeList',
				name: 'selectMallTypeList',
				component: () => import('../views/selectMall/typeList'),
				props: true
			},
			{
				path: '/selectMall/product',
				name: 'selectMallProduct',
				component: () => import('../views/selectMall/product'),
				props: true
			},
			{
				path: '/selectMall/product/add',
				name: 'selectMallProductAdd',
				component: () => import('../views/selectMall/productAdd'),
				props: true
			},
			{
				path: '/selectMall/express',
				name: 'selectMallExpress',
				component: () => import('../views/selectMall/express'),
				props: true
			},
			{
				path: '/selectMall/shop',
				name: 'selectMallShop',
				component: () => import('../views/selectMall/shop'),
				props: true
			},
			{
				path: '/afterShopList',
				name: 'afterShopList',
				component: () => import('../views/selectMall/afterShopList'),
				props: true
			},
			{
				path: '/afterShopDetails',
				name: 'afterShopDetails',
				component: () => import('../views/selectMall/afterShopDetails'),
				props: true
			},
			{
				path: '/selectMall/shop/add/:id',
				name: 'selectMallShopAdd',
				component: () => import('../views/selectMall/shopAdd'),
				props: true
			},
			{
				path: '/selectMall/coupon',
				name: 'selectMallCoupon',
				component: () => import('../views/selectMall/coupon'),
				props: true
			},
			{
				path: '/couponCodeList/:id',
				name: 'couponCodeList',
				component: () => import('../views/marketing/coupon/couponCodeList'),
				props: true
			},
			{
				path: '/selectMall/order/list',
				name: 'selectMallOrderList',
				
				component: () => import('../views/selectMall/order/list'),
				props: true
			},
			{
				path: '/selectMall/order/detail',
				name: 'selectMallOrderDetail',
				component: () => import('../views/selectMall/order/detail'),
				props: true
			},
			{
				path: '/selectMall/order/service',
				name: 'selectMallOrderService',
				component: () => import('../views/selectMall/order/service'),
				props: true
			},
			{
				path: '/selectMall/order/serviceDetail',
				name: 'selectMallOrderServiceDetail',
				component: () =>
					import('../views/selectMall/order/serveDetail'),
				props: true
			},
			// 精选商城 end

			//万旅卡
			{
				path: '/wanlvCategory',
				name: 'wanlvCategory',
				component: () => import('../views/wanlvCard/category')
			},
			{
				path: '/wanlvcard',
				name: 'wanlvcard',
				component: () => import('../views/wanlvCard/card')
			},
			{
				path: '/addCard/:cardId',
				name: 'addCard',
				component: () => import('../views/wanlvCard/addCard'),
				props: true
			},
			//万旅卡结束

			//万小店
			{
				path: '/basicSetup',
				name: 'basicSetup',
				component: () => import('../views/wanxiaoShop/basicSetup')
			},			
			{
				path: '/inviteSetting',
				name: 'inviteSetting',
				component: () => import('../views/wanxiaoShop/inviteSetting')
			},
			{
				path: '/activitySettingList',
				name: 'activitySettingList',
				component: () => import('../views/wanxiaoShop/activitySettingList')
			},
			{
				path: '/rewardList',
				name: 'rewardList',
				component: () => import('../views/wanxiaoShop/rewardList')
			},
			{
				path: '/escalationCondition',
				name: 'escalationCondition',
				component: () => import('../views/wanxiaoShop/escalationCondition')
			},
			{
				path: '/distributionActivityList',
				name: 'distributionActivityList',
				component: () => import('../views/wanxiaoShop/distributionActivityList')
			},
			{
				path: '/addActivityList',
				name: 'addActivityList',
				component: () => import('../views/wanxiaoShop/addActivityList')
			},
			{
				path: '/upgradePrizeList',
				name: 'upgradePrizeList',
				component: () => import('../views/wanxiaoShop/upgradePrizeList')
			},
			{
				path: '/upgradePrizeListAdd',
				name: 'upgradePrizeListAdd',
				component: () => import('../views/wanxiaoShop/upgradePrizeListAdd')
			},
			{
				path: '/prizeList',
				name: 'prizeList',
				component: () => import('../views/wanxiaoShop/prizeList')
			},		
			{
				path: '/invitationOrder',
				name: 'invitationOrder',
				component: () => import('../views/wanxiaoShop/invitationOrder')
			},		
			
			{
				path: '/prizeListAdd',
				name: 'prizeListAdd',
				component: () => import('../views/wanxiaoShop/prizeListAdd')
			},			
			{
				path: '/storeRetailRecord',
				name: 'storeRetailRecord',
				component: () => import('../views/wanxiaoShop/storeRetailRecord')
			},
			{
				path: '/levelList',
				name: 'levelList',
				component: () => import('../views/wanxiaoShop/levelList')
			},
			{
				path: '/productList',
				name: 'productList',
			

				component: () => import('../views/wanxiaoShop/productList')
			},
			{
				path: '/materialCate',
				name: 'materialCate',
				component: () => import('../views/wanxiaoShop/materialCate')
			},
			{
				path: '/materialList',
				name: 'materialList',
				component: () => import('../views/wanxiaoShop/materialList')
			},
			{
				path: '/shopowner',
				name: 'shopowner',
				component: () => import('../views/wanxiaoShop/shopowner')
			},
			{
				path: '/shopUserList',
				name: 'shopUserList',


				component: () => import('../views/wanxiaoShop/shopUserList')
			},
			{
				path: '/shopUserList',
				name: 'shopUserList',
				component: () => import('../views/wanxiaoShop/shopUserList')
			},
			{
				path: '/shopRetailRecord',
				name: 'shopRetailRecord',
				component: () =>
					import('../views/wanxiaoShop/shopRetailRecord')
			},
			{
				path: '/shopOwnerRecord',
				name: 'shopOwnerRecord',
				component: () =>
					import('../views/wanxiaoShop/shopOwnerRecord')
			},
			{
				path: '/fingerpostList',
				name: 'fingerpostList',
				component: () =>
					import('../views/wanxiaoShop/fingerpost/fingerpostList.vue')
			},
			{
				path: '/addFinger',
				name: 'addFinger',
				component: () =>
					import('../views/wanxiaoShop/fingerpost/addFinger.vue')
			},
			{
				path: '/taskList',
				name: 'taskList',
				component: () =>
					import('../views/wanxiaoShop/task/taskList.vue')
			},
			{
				path: '/taskShopList',
				name: 'taskShopList',
				component: () =>
					import('../views/wanxiaoShop/task/taskShopList.vue')
			},
			{
				path: '/district',
				name: 'district',
				component: () =>
					import('../views/wanxiaoShop/district/district.vue')
			},
			{
				path: '/invitationRegister',
				name: 'invitationRegister',
				component: () =>
					import('../views/wanxiaoShop/invitationRegister.vue')
			},
			{
				path: '/overlayActivitySetting',
				name: 'overlayActivitySetting',
				component: () =>
					import('../views/wanxiaoShop/overlayActivitySetting.vue')
			},
			{
				path: '/distributionPoster',
				name: 'distributionPoster',
				component: () =>
					import('../views/wanxiaoShop/distributionPoster.vue')
			},
			//万小店结束

			// 财务管理 开始
			{
				path: '/rechargeRecord',
				name: 'rechargeRecord',
				component: () => import('../views/Finance/rechargeRecord')
			},
			{
				path: '/withdrawalRecord',
				name: 'withdrawalRecord',
				component: () => import('../views/Finance/withdrawalRecord')
			},
			{
				path: '/bankList',
				name: 'bankList',
				component: () => import('../views/Finance/bankList')
			},
			{
				path: '/signingList',
				name: 'signingList',
				component: () => import('../views/Finance/signingList')
			},

			{
				path: '/taxRateList',//税费列表
				name: 'taxRateList',
				component: () => import('../views/Finance/taxRateList')
			},
			// 财务管理 结束

			//订单
			{
				path: '/orderList/:type',
				name: 'orderList',
				
				component: () => import('../views/order/order'),
				props: true
			},
			{
				path: '/orderAll',
				name: 'orderAll',
				component: () => import('../views/order/orderAll.vue'),
				props: true
			},
			// {
			// 	path: '/orderDetail/:type/:coding',
			// 	name: 'orderDetail',
			// 	component: () => import('../views/order/orderDetail'),
			// 	props: true
			// },
			{
				path: '/groupBuyOrderdetail/:type/:orderId',
				name: 'groupBuyOrderdetail',
				component: () => import('../views/order/groupBuyOrderdetail'),
				props: true
			},
			{
				path: '/orderRefund',
				name: 'orderRefund',
				component: () => import('../views/order/orderRefund')
			},
			{
				path: '/refundOrderdetail/:coding',
				name: 'refundOrderdetail',
				component: () => import('../views/order/refundOrderdetail'),
				props: true
			},
			,
			//订单结束

			// 统计
			{
				path: '/memberStatistics',
				name: 'memberStatistics',
				component: () =>
					import('../views/statistics/memberStatistics')
			},
			{
				path: '/rankingList',
				name: 'rankingList',
				component: () =>
					import('../views/statistics/rankingList')
			},
			{
				path: '/orderStatistics',
				name: 'orderStatistics',
				component: () => import('../views/statistics/orderStatistics')
			},
			{
				path: '/balanceJournal',
				name: 'balanceJournal',
				component: () => import('../views/statistics/balanceJournal')
			},
			{
				path: '/productStatistics',
				name: 'productStatistics',
				component: () =>
					import('../views/statistics/productStatistics')
			},
			{
				path: '/memberConsume',
				name: 'memberConsume',
				component: () => import('../views/statistics/memberConsume')
			},
			{
				path: '/memberBuys',
				name: 'memberBuys',
				component: () => import('../views/statistics/memberBuys')
			},
			{
				path: '/addSupplier', //添加供应商
				name: 'addSupplier',
				component: () => import('../views/statistics/addSupplier')
			},
			{
				path: '/supplier', //供应商列表
				name: 'supplier',
				component: () => import('../views/statistics/supplier')
			},
			{
				path: '/supplierDetail', //供应商详情
				name: 'supplierDetail',
				component: () => import('../views/statistics/supplierDetail')
			},
			// 统计结束
			//app管理
			{
				path: '/appList', //供应商详情
				name: 'appList',
				component: () => import('../views/appManage/appList')
			},
			{
				path: '/addApp', //供应商详情
				name: 'addApp',
				component: () => import('../views/appManage/addApp')
			},
			{
				path: '/pushMessage', //消息推送管理
				name: 'addApp',
				component: () => import('../views/appManage/pushMessage')
			},
			{
				path: '/pushMessageList', //消息推送管理
				name: 'addApp',
				component: () => import('../views/appManage/pushMessageList')
			},
			//营销工具
			{
				path: '/evalList',
				name: 'evalList',
				component: () =>
					import('../views/marketing/eval/evalList.vue'),
				props: true
			},
			{
				path: '/hobbyList',
				name: 'hobbyList',
				component: () =>
					import('../views/marketing/hobbyList.vue'),
				props: true
			},
			{
				path: '/industryList',
				name: 'industryList',
				component: () =>
					import('../views/marketing/industryList.vue'),
				props: true
			},
			{
				path: '/winpointsSetting',
				name: 'winpointsSetting',
				component: () =>
					import('../views/marketing/winpointsSetting.vue'),
				props: true
			},
			{
				path: '/receiveVip',
				name: 'receiveVip',
				component: () =>
					import('../views/marketing/receiveVip.vue'),
				props: true
			},
			
			//会员日产品
			{
				path: '/memberRight',
				name: 'memberRight',
				component: () => import('../views/marketing/memberRight.vue'),
				props: true
			},
			//会员日设置
			{
				path: '/userTime',
				name: 'userTime',
				component: () => import('../views/marketing/userTime.vue'),
				props: true
			},
			{
				path: '/birthdayRight',
				name: 'birthdayRight',
				component: () =>
					import('../views/marketing/birthdayRight.vue'),
				props: true
			},
			// 满减
			{
				path: '/fullDecrementList',
				name: 'fullDecrementList',
				component: () =>
					import('../views/marketing/fullDecrement/fullDecrementList.vue'),
				
			},
				// 满减添加
			{
				path: '/addFullDecrement/:id',
				name: 'addFullDecrement',
				component: () =>
					import('../views/marketing/fullDecrement/addFullDecrement.vue'),
					props: true
			},
			//营销工具
			{
				path: '/vipdetail/:userId',
				name: 'vipdetail',
				component: () => import('../views/vip/vipdetail.vue'),
				props: true
			},
			{
				path: '/balanceRecord/:userId',
				name: 'balanceRecord',
				component: () => import('../views/vip/balanceRecord.vue'),
				props: true
			},
			{
				path: '/interRecord/:userId',
				name: 'interRecord',
				component: () => import('../views/vip/interRecord.vue'),
				props: true
			},
			{
				path: '/distributionMore',
				name: 'distributionMore',
				component: () => import('../views/vip/distributionMore.vue'),
				
			},
			{
				path: '/suggestionList',
				name: 'suggestionList',
				component: () => import('../views/vip/suggestionList.vue'),
				props: true
			},
			{
				path: '/feeback',
				name: 'feeback',
				component: () => import('../views/vip/feeback.vue'),
				props: true
			},
			{
				path: '/activeList',
				name: 'activeList',
				component: () =>
					import('../views/marketing/activePage/activeList.vue'),
				props: true
			},
			{
				path: '/addActive/:id',
				name: 'addActive',
				component: () =>
					import('../views/marketing/activePage/addActive.vue'),
				props: true
			},
			{
				path: '/regionalManagement/:id',
				name: 'regionalManagement',
				component: () =>
					import('../views/marketing/activePage/regionalManagement.vue'),
				props: true
			},
			{
				path: '/visitDetails/:activityId/:areaId',
				name: 'visitDetails',
				component: () =>
					import('../views/marketing/activePage/visitDetails.vue'),
				props: true
			},
			{
				path: '/orderDetails/:type',
				name: 'orderDetails',
				component: () =>
					import('../views/marketing/activePage/orderDetails.vue'),
				props: true
			},
			{
				path: '/403',
				name: '403',
				component: () => import('../components/403.vue')
			},
			{
				path: '/500',
				name: '500',
				component: () => import('../components/500.vue')
			},
			{
				path: '/gameTools',
				name: 'gameTools',
				component: () =>
					import('../views/marketing/gameTools/gameTools.vue')
			},
			{
				path: '/addGame',
				name: 'addGame',
				component: () =>
					import('../views/marketing/gameTools/addGame.vue')
			},
			{
				path: '/editGame',
				name: 'editGame',
				component: () =>
					import('../views/marketing/gameTools/editGame.vue')
			},
			{
				path: '/smart',
				name: 'smart',
				component: () => import('../views/marketing/smart/smart.vue')
			},
			{
				path: '/addSmartClock',
				name: 'addSmartClock',
				component: () =>
					import('../views/marketing/smart/addSmarClock.vue')
			},
			{
				path: '/addGameLine',
				name: 'addGameLine',
				component: () =>
					import('../views/marketing/smart/addGameLine.vue')
			},
			{
				path: '/gameLine',
				name: 'gameLine',
				component: () =>
					import('../views/marketing/smart/gameLine.vue')
			},
			{
				path: '/test',
				name: 'test',
				component: () =>
					import('../views/test.vue')
			},
			{
				path: '/gameLineManage',
				name: 'gameLineManage',
				component: () =>
					import('../views/marketing/smart/gameLineManage.vue')
			},
			{
				path: '/groundPush',
				name: 'gameLineManage',
				component: () =>
					import('../views/marketing/groundPush/groundPush.vue')
			},
			{
				path: '/addGroundPush',
				name: 'addGroundPush',
				component: () =>
					import('../views/marketing/groundPush/addGroundPush.vue')
			},
			{
				path: '/warehouse',
				name: 'warehouse',
				component: () =>
					import('../views/selectMall/warehouse.vue')
			},
			{
				path: '/balanceJournalDetails',
				name: 'balanceJournalDetails',
				component: () =>
					import('../views/statistics/balanceJournalDetails.vue')
			},
			{
				path: '/levelJournal',
				name: 'levelJournal',
				component: () =>
					import('../views/wanxiaoShop/levelJournal.vue')
			},
			{
				path: '/dataView',
				name: 'dataView',
				component: () =>
					import('../views/wanxiaoShop/dataView.vue')
			},
			{
				path: '/shopJournal',
				name: 'shopJournal',
				component: () =>
					import('../views/wanxiaoShop/shopJournal.vue')
			},
			{
				path: '/goodsJournal',
				name: 'goodsJournal',
				component: () =>
					import('../views/wanxiaoShop/goodsJournal.vue')
			},
			// 美食管理
			{
				path: '/foodCateList',
				name: 'foodCateList',
			
				component: () =>
					import('../views/food/foodCateList.vue')
			},
			{
				path: '/foodList',
				name: 'foodList',
				
				component: () =>
					import('../views/food/foodList.vue')
			},
			{
				path: '/foodAdd',
				name: 'foodAdd',
				component: () =>
					import('../views/food/foodAdd.vue')
			},
			{
				path: '/foodPackageCate',
				name: 'foodPackageCate',
				component: () =>
					import('../views/food/foodPackageCate.vue')
			},
			{
				path: '/foodPackList',
				name: 'foodPackList',
				component: () =>
					import('../views/food/foodPackList.vue')
			},
			{
				path: '/foodPackAdd',
				name: 'foodPackAdd',
				component: () =>
					import('../views/food/foodPackAdd.vue')
			},
			{
				path: '/foodPackListwrite',
				name: 'foodPackListwrite',
				component: () =>
					import('../views/food/foodPackListwrite.vue')
			},
			{
				path: '/foodPackStock/:id',
				name: 'foodPackStock',
				props: true,
				component: () =>
					import('../views/food/foodPackStock.vue')
			},
			{
				path: '/foodLog/:id',
				name: 'foodLog',
				props: true,
				component: () =>
					import('../views/food/foodLog.vue')
			},
			{
				path: '/foodLogDetails/:id',
				name: 'foodLogDetails',
				props: true,
				component: () =>
					import('../views/food/foodLogDetails.vue')
			},
			{
				path: '/foodPackLog/:id',
				name: 'foodPackLog',
				props: true,
				component: () =>
					import('../views/food/foodPackLog.vue')
			},
			{
				path: '/foodPackLogDetails/:id',
				name: 'foodPackLogDetails',
				props: true,
				component: () =>
					import('../views/food/foodPackLogDetails.vue')
			},
			{
				path: '/foodScenic',
				name: 'foodScenic',
				props: true,
				component: () =>
					import('../views/food/foodScenic.vue')
			},

			{
				path: '/seckill/',
				name: 'seckill',
				props: true,
				component: () =>
					import('../views/basicMarketing/seckill.vue')
			},
			{
				path: '/presell',
				name: 'presell',
				props: true,
				component: () =>
					import('../views/basicMarketing/presell.vue')
			},
			
		]
	}
]

const router = new VueRouter({
	routes,
	base: '/static/'
})
export function initDynamicRoutes() {
	console.log(router.options.routes)
}
export default router
