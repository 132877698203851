<template>
  <div id="app">
    
    
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  created() {},
};
</script>
<style>
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard syntax */
}
</style>
<style lang="less" scoped>

#app {
  height: 100vh;
  width: 100vw;
}
</style>