import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import('./assets/css/base.css')
import './utils/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import VueQuillEditor from 'vue-quill-editor'


import '../public/UEditor1/ueditor.config'
import '../public/UEditor1/ueditor.all.min.js'
import '../public/UEditor1/lang/zh-cn/zh-cn.js'
import '../public/UEditor1/ueditor.parse.min.js'

import Print from './utils/print'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import aMap from 'vue-amap'
Vue.use(aMap)

// aMap.initAMapApiLoader({
//   key: '4b9984cf40d2eecaaec7f641e353b3f8',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],//应用功能项
//   v: '1.4.4',
//   uiVersion: '1.0'
// })

Vue.use(VueQuillEditor,)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Print)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
